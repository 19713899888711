@font-face {
  font-family: 'nsx-nourish-icons';
  src:  url('../../assets/fonts/nsx-nourish-icons.eot');
  src:  url('../../assets/fonts/nsx-nourish-icons.eot?#iefix') format('embedded-opentype'),
    url('../../assets/fonts/nsx-nourish-icons.ttf') format('truetype'),
    url('../../assets/fonts/nsx-nourish-icons.woff') format('woff'),
    url('../../assets/fonts/nsx-nourish-icons.svg?#nsx-nourish-icons') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="nsx-font-icon--"], [class*=" nsx-font-icon--"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'nsx-nourish-icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.nsx-font-icon--actions-history:before {
  content: "\e935";
}
.nsx-font-icon--assessments:before {
  content: "\e933";
}
.nsx-font-icon--integrations:before {
  content: "\e92d";
}
.nsx-font-icon--care-plan:before {
  content: "\e930";
}
.nsx-font-icon--info:before {
  content: "\e92a";
}
.nsx-font-icon--cancel:before {
  content: "\e92b";
}
.nsx-font-icon--family-portal:before {
  content: "\e928";
}
.nsx-font-icon--circle-of-care:before {
  content: "\e929";
}
.nsx-font-icon--file-zip:before {
  content: "\e91d";
}
.nsx-font-icon--file-png:before {
  content: "\e91e";
}
.nsx-font-icon--file-generic:before {
  content: "\e91f";
}
.nsx-font-icon--file-pdf:before {
  content: "\e920";
}
.nsx-font-icon--file-txt:before {
  content: "\e921";
}
.nsx-font-icon--file-xls:before {
  content: "\e922";
}
.nsx-font-icon--file-gif:before {
  content: "\e923";
}
.nsx-font-icon--file-jpg:before {
  content: "\e924";
}
.nsx-font-icon--file-doc:before {
  content: "\e925";
}
.nsx-font-icon--file-csv:before {
  content: "\e926";
}
.nsx-font-icon--file-avi:before {
  content: "\e927";
}
.nsx-font-icon--page:before {
  content: "\e91c";
}
.nsx-font-icon--filter:before {
  content: "\e91a";
}
.nsx-font-icon--checkbox-checked:before {
  content: "\e91b";
}
.nsx-font-icon--dash:before {
  content: "\e919";
}
.nsx-font-icon--arrow-left:before {
  content: "\e912";
}
.nsx-font-icon--arrow-right:before {
  content: "\e913";
}
.nsx-font-icon--arrow-up:before {
  content: "\e911";
}
.nsx-font-icon--arrow-down:before {
  content: "\e910";
}
.nsx-font-icon--plus:before {
  content: "\e653";
}
.nsx-font-icon--minus:before {
  content: "\e906";
}
.nsx-font-icon--person:before {
  content: "\e63f";
}
.nsx-font-icon--people:before {
  content: "\e640";
}
.nsx-font-icon--add-person:before {
  content: "\e64b";
}
.nsx-font-icon--add-people:before {
  content: "\e656";
}
.nsx-font-icon--assign-carer:before {
  content: "\e639";
}
.nsx-font-icon--circle-care:before {
  content: "\e64d";
}
.nsx-font-icon--dashboards:before {
  content: "\e953";
}
.nsx-font-icon--changelog:before {
  content: "\e901";
}
.nsx-font-icon--calendar:before {
  content: "\e905";
}
.nsx-font-icon--login:before {
  content: "\e90c";
}
.nsx-font-icon--logout:before {
  content: "\e90a";
}
.nsx-font-icon--services:before {
  content: "\e90b";
}
.nsx-font-icon--download:before {
  content: "\e90f";
}
.nsx-font-icon--settings:before {
  content: "\e64a";
}
.nsx-font-icon--gears:before {
  content: "\e914";
}
.nsx-font-icon--undo:before {
  content: "\e915";
}
.nsx-font-icon--search:before {
  content: "\e903";
}
.nsx-font-icon--permissions:before {
  content: "\e62b";
}
.nsx-font-icon--bell:before {
  content: "\e637";
}
.nsx-font-icon--bell-cancel:before {
  content: "\e90d";
}
.nsx-font-icon--message:before {
  content: "\e638";
}
.nsx-font-icon--checked:before {
  content: "\e63b";
}
.nsx-font-icon--rotas:before {
  content: "\e63e";
}
.nsx-font-icon--report:before {
  content: "\e641";
}
.nsx-font-icon--timeline:before {
  content: "\e644";
}
.nsx-font-icon--help:before {
  content: "\e907";
}
.nsx-font-icon--profile:before {
  content: "\e64c";
}
.nsx-font-icon--remove:before {
  content: "\e654";
}
.nsx-font-icon--delete:before {
  content: "\e655";
}
.nsx-font-icon--audit-trail:before {
  content: "\e917";
}
.nsx-font-icon--edit:before {
  content: "\e916";
}
.nsx-font-icon--kebab-menu:before {
  content: "\e902";
}
.nsx-font-icon--handover:before {
  content: "\e904";
}
.nsx-font-icon--nfc:before {
  content: "\e908";
}
.nsx-font-icon--snooze:before {
  content: "\e909";
}
.nsx-font-icon--attached-document:before {
  content: "\e90e";
}
.nsx-font-icon--house:before {
  content: "\e918";
}
.nsx-font-icon--customer-support:before {
  content: "\e92c";
}

.nsx-font-icon--device-control-1:before {
  content: "\e92e";
}

.nsx-font-icon--icon-emergencypack:before {
  content: "\e931";
}

.nsx-font-icon--icon-download-only:before {
  content: "\e932";
}

.nsx-font-icon--analytics:before {
  content: "\e934";
}

.nsx-font-icon--content-copy:before {
  content: "\e936";
  color: $brand-black;
}

.nsx-font-icon--account-esc:before {
  content: "\e937";
}

.nsx-font-icon--bookmark:before {
  content: "\e938";
}

.nsx-font-icon--care-plan-template:before {
  content: "\e939";
  width: 25px;
  height: 25px;
}

.nsx-font-icon--body-map:before {
  content: "\e93a";
}

.nsx-font-icon--crop:before {
  content: "\e93b";
  width: 25px;
  height: 25px;
}

.nsx-font-icon--rotate_left:before {
  content: "\e93c";
  width: 25px;
  height: 25px;
}

.nsx-font-icon--flash_off:before {
  content: "\e93d";
  width: 25px;
  height: 25px;
}

.nsx-font-icon--flash_on:before {
  content: "\e93e";
  width: 25px;
  height: 25px;
}

.nsx-font-icon--img_placeholder:before {
  content: "\e93f";
  width: 25px;
  height: 25px;
}

.nsx-font-icon--file_download:before {
  content: "\e940";
  width: 25px;
  height: 25px;
}

.nsx-font-icon--delete_bin:before {
  content: "\e941";
  width: 25px;
  height: 25px;
}

.nsx-font-icon--eye_crossed:before {
  content: "\e942";
  width: 25px;
  height: 25px;
}

.nsx-font-icon--add_photo_alternate:before {
  content: "\e943";
  width: 25px;
  height: 25px;
}

.nsx-font-icon--add_a_photo:before {
  content: "\e944";
  width: 25px;
  height: 25px;
}

.nsx-font-icon--camera:before {
  content: "\e945";
  width: 25px;
  height: 25px;
}

.nsx-font-icon--folder:before {
  content: "\e946";
  width: 25px;
  height: 25px;
}

.nsx-font-icon--new-folder:before {
  content: "\e947";
}

.nsx-font-icon--timeline-dotted:before {
  content: "\e948";
}

.nsx-font-icon--eye:before {
  content: "\e949";
}

.nsx-font-icon--expand-bottom:before {
  content: "\e94b";
}

.nsx-font-icon--expand-left:before {
  content: "\e94a";
}
.nsx-font-icon--expand-top:before {
  content: "\e94c";
}

.nsx-font-icon--data-retention:before {
  content: "\e94d";
}

.nsx-font-icon--care_plan_signature:before {
  content: "\e94e";
}

.nsx-font-icon--circled_logo:before {
  content: "\e94f";
}

.nsx-font-icon--events:before {
  content: "\e900";
}

.nsx-font-icon--spinner_1:before {
  content: "\e92f";
}

.nsx-font-icon--spinner_2:before {
  content: "\e950";
}

.nsx-font-icon--spinner_3:before {
  content: "\e951";
}

.nsx-font-icon--spinner_4:before {
  content: "\e952";
}

.nsx-font-icon--new-item:before {
  content: "\e954";
}

.nsx-font-icon--new-item-outlined:before {
  content: "\e957";
}

.nsx-font-icon--launcher:before {
  content: "\e958";
}

.nsx-font-icon--report-circular:before {
  content: "\e95b";
}

.nsx-font-icon--medication:before {
  content: "\e955";
}

.nsx-font-icon--image-gallery:before {
  content: "\e956";
}

.nsx-font-icon--padlock::before {
  content: "\e959";
}

.nsx-font-icon--conflict::before {
  content: "\e95a";
}

.nsx-font-icon--clone::before {
  content: "\e95c";
}

